@import "../../style/style.scss";

.zg-welcome {
  box-shadow: $box-shadow;
  margin-top: 0;
  border-radius: 5px 5px 5px 5px;
  min-width: 85vw;
  min-height: 100vh;
  color: $zg-purple;
  .zg-welcome-header {
    display: flex;
    justify-content: center;
    .zg-welcome-header-text {
      padding: 5px;
      color: $zg-purple;
    }
  }
  .zg-welcome-text {
    font-weight: bold;
    margin: 20px 5px 0 5px;
  }
}

@media only screen and (min-width: 501px) {
  .zg-welcome {
    .zg-welcome-header {
      .zg-welcome-header-text {
      }
    }
    .zg-welcome-text {
    }
  }
}
