@import "../../style/style.scss";

.zg-login {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .zg-login-title {
    font-family: $zg-font;
    color: $zg-purple;
    
  }
  .zg-login-portal {
    margin: 20px;
    display: flex;
    flex-direction: column;
    .zg-login-username {
      border: none;
      
      font-size: 18px;
      height: 30px;
      margin: 30px 40px 10px 40px;
      padding-left: 7px;
      color: $zg-purple;
    }
    .zg-login-password {
      color: $zg-purple;
      margin: 20px 40px;
      height: 30px;
      padding-left: 7px;
      border: none;
      
      font-size: 18px;
    }
    .zg-login-button:hover {
      cursor: pointer;
    }
    .zg-login-button {
      background: white;
      color: $zg-purple;
      font-weight: bolder;
      font-size: 15px;
      margin: 20px 40px;
      border: none;
      font-family: $zg-font;
      font-size: 18px;
    }
    .zg-forgot-password {
      font-size: 18px;
      font-weight: bolder;
      height: 30px;
      color: $zg-purple;
      
      font-size: 18px;
      text-decoration: none;
    }
    .zg-sign-up-button:hover {
      cursor: pointer;
    }
    .zg-sign-up-button {
      background: white;
      color: $zg-purple;
      font-weight: bolder;
      font-size: 18px;
      margin: 20px 40px;
      height: 40px;
      border: none;
      // font-family: $zg-font;
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-login {
    min-height: 580px;
    margin: 80px 0;
    display: flex;
    flex-direction: row;
    .zg-login-title {
      margin-top: 70px;
      max-height: 180px;
      display: flex;
      align-items: center;
    }
    .zg-login-portal {
      max-height: 400px;
    }
  }
}
