@import "../../style/style.scss";

.zg-contribution-content {
  text-align: left;
  align-items: flex-start;
  justify-content: left;
  margin: 5px 0;
  width: 320px;
  white-space: normal;
  border: none;
  outline: none;
  color: $zg-purple;
  font-size: 14px;
  font-weight: bolder;
  font-family: $zg-font;
  background: none;
  overflow-wrap: break-word;
}

.zg-contribution-author {
  cursor: pointer;
}

.zg-edit-contribution-modal {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .zg-edit-contribute-body {
    border-radius: 5px;
    text-align: center;
    height: 400px;
    width: 300px;
    position: absolute;
    background: white;
    color: $zg-purple;

    .zg-edit-contribution-input {
      margin-top: 20px;
      width: 250px;
      height: 100px;
      font-size: 18px;
      font-weight: bold;
      font-family: $zg-font;
      border: none;
      outline: none;
      color: $zg-purple;
    }
    .zg-submit-edit-contribution {
      font-family: $zg-font;
      color: $zg-purple;
      
      font-weight: bolder;
    }
    .zg-disabled {
      opacity: 0.5;
    }
    .zg-cancel-edit {
      color: $zg-purple;
      font-family: $zg-font;
      font-weight: bolder;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-contribution-content {
    min-width: 23vw;
    margin: 5px 0;
    padding: 0;
  }
  .zg-edit-contribution-modal {
    .zg-edit-contribute-body {
      height: 500px;
      width: 700px;
      .zg-edit-contribution-input {
        width: 400px;
        height: 270px;
      }
      .zg-submit-edit-contribution {
      }
    }
  }
}
