@import "../../style/style.scss";

.zg-contribution-container {
  // background: yellowgreen;
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 0.1px rgb(196, 196, 196) solid;
  .zg-content-and-author {
    .zg-vote-section {
      display: flex;
      flex-direction: row;
    }
    .zg-contribution-creator {
      padding-left: 8px;
      padding-bottom: 5px;
      float: left;
      color: grey;
      font-family: $zg-font-italic;
      font-size: 12px;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-contribution-container {
    display: flex;
    justify-content: space-evenly;
    min-width: 30vw;
    .zg-content-and-author {
      .zg-vote-section {
      }
      .zg-contribution-creator {
        // font-size: 14px;
      }
    }
  }
}
