.App {
  text-align: center;
  .zg-routes {
    
  }
}

@media only screen and (min-width: 501px) {
  .App {
  }
  .zg-routes {

  }
}



