@import "../../style/style.scss";

h1 {
  font-family: $zg-font;
}

.zg-register-button {
  cursor: pointer;
  margin: 20px 0;
  border: none;
  outline: none;
  color: $zg-purple;
  font-size: 17px;
  font-weight: bolder;
  background: none;
  font-family: $zg-font;
  
}

.zg-register-modal {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .zg-register-modal-body {
    background: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    // justify-content: s;

    border-radius: 5px;
    height: 450px;
    width: 330px;
    position: absolute;

    color: $zg-purple;
    h1 {
      margin-top: 50px;
    }
    .zg-password-invalid {
      margin-top: 25px;
      font-weight: bolder;
      color: red;
    }
    .zg-register-form {
      height: 25px;
      border: none;
      background: none;
      margin-top: 25px;
      color: $zg-purple;
      
      font-weight: bolder;
      font-size: 18px;
    }
    .form-button {
      width: 100px;
      cursor: pointer;
      font-family: $zg-font;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-register-modal {
    .zg-register-modal-body {
      background: white;
      height: 500px;
      width: 700px;
    }
  }
}
