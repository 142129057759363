@import "../../style/style.scss";

.zg-contribution {
  outline: 0;

  .zg-modal-contribute {
    box-shadow: $box-shadow;
    font-family: $zg-font;
    width: 170px;
    color: $zg-purple;
    text-decoration: none;
    font-weight: bolder;
    margin-right: 5px;
    background: white;
    font-family: $zg-font;
    box-shadow: $box-shadow;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    border: solid 1px #d3b349;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 5px;
  }

  .zg-plus-icon {
    margin: 0 4px;
    height: 17px;
  }
  .zg-modal-contribute:hover {
    cursor: pointer;
  }
}

.zg-contribution-modal {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  .zg-contribute-body {
    border-radius: 5px;
    text-align: center;
    min-height: 470px;
    width: 330px;
    position: absolute;
    background: white;
    color: $zg-purple;

    p {
      margin-bottom: 2px;
    }
    .zg-choice-group {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .zg-choice-agree,
      .zg-choice-neutral,
      .zg-choice-disagree {
        width: 80px;
        box-shadow: $box-shadow;
        margin: 20px 0 0 0;
        background: white;
        color: $zg-purple;
        text-decoration: none;
        font-weight: bolder;
        font-family: $zg-font;
      }
      .zg-choice-selected {
        color: white;
        background: $zg-purple;
      }
    }

    .zg-contribution-input {
      margin-top: 20px;
      width: 250px;
      height: 100px;
      font-size: 18px;
      font-weight: bold;
      border: none;
      outline: none;
      color: $zg-purple;
    }
    .zg-submit-contribution {
      background: white;
      color: $zg-purple;
      font-family: $zg-font;
      font-weight: bolder;
    }
    .zg-contribution-valid {
      background: #d3b349;
      box-shadow: $box-shadow;
    }
    .zg-disabled {
      opacity: 0.5;
    }
    .zg-cancel-contribution-modal {
      margin-top: 10px;
      background: white;
      color: $zg-purple;
      font-family: $zg-font;
      font-weight: bolder;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-contribution {
    .zg-modal-contribute {
      box-shadow: $box-shadow;
      font-family: $zg-font;
      font-size: 10px;
      width: 120px;
      color: $zg-purple;
      text-decoration: none;
      font-weight: bolder;
    }
  }
  .zg-contribution-modal {
    .zg-contribute-body {
      height: 550px;
      width: 700px;
      .zg-choice-group {
        .zg-choice-selected {
          font-family: $zg-font;
        }
        .zg-choice-agree,
        .zg-choice-neutral,
        .zg-choice-disagree {
          width: 170px;
        }
      }
      .zg-contribution-input {
        width: 400px;
        height: 270px;
      }
      .zg-submit-contribution {
      }
    }
  }
}
