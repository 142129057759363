@import "../../style/style.scss";

.zg-categories-bar {
  text-align: left;
  margin: 0 40px;
  margin-top: 20px;
  .zg-categories-bar-header {
    font-size: 22px;
    font-weight: bolder;
    color: $zg-purple;
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 5px;
    color: $zg-purple;
    // border-bottom: 1px solid $zg-purple;
    // border-top: 1px solid $zg-purple;
    text-align: center;
  }

  .zg-category-container {
    font-size: 10px;
    .zg-category-link-holder {
      padding: 10px;
      margin: 5px;
      .zg-single-category {
        font: $zg-font;
        text-align: left;
        font-size: 20px;
        font-weight: bolder;
        text-decoration: none;
        cursor: pointer;
        padding: 5px;
        border-radius: 3px;
        color: $zg-purple;
        border: solid 1px $zg-purple;
        width: 100%;
        background: white;
        text-align: center;
        box-shadow: $box-shadow;
        .zg-category-text {
          font-weight: bold;
          font-size: 13px;
          font: $zg-font;
        }
      }
    }
  }
}

.zg-category-popout {
}

@media only screen and (min-width: 501px) {
  .zg-categories-bar-holder {
    display: flex;
    margin-top: 20px;
    .zg-categories-bar {
      width: 164px;
      padding-top: 10px;
      margin: 0 0 0 0px;
      min-height: 100vh;
      border-radius: 0 5px 5px 0;
      .zg-categories-bar-header {
        width: 100%;
        font-size: 15px;
        font-weight: bolder;
        margin-left: 29px;
        max-width: fit-content;
      }
      .zg-category-container {
        align-items: flex-start;

        .zg-category-link-holder {
          margin: 5px 15px 5px 5px;
          padding: 5px;
          .zg-single-category {
            font-size: 11px;
            border-radius: 3px;
            box-shadow: none;
            .zg-category-text {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
