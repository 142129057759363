@import "../../style/style.scss";

.zg-header {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: $box-shadow;


  .zg-header-home {
    font-weight: bolder;
    font-size: 20px;
    color: $zg-purple;
    text-decoration: none;
    margin: 20px 0 15px 40px;
  }
  .zg-header-login {
    font-weight: bold;
    font-size: 18px;
    color: $zg-purple;
    margin: 22px 40px 15px 0px;
    text-decoration: none;
  }
}

@media only screen and (min-width: 501px) {
  .zg-header-home {
    font-size: 30px;
  }
  .zg-header-login {
    font-size: 22px;
  }
}
