@import "../../style/style.scss";

.zg-current-discussion {
  min-height: 570px;
  text-align: center;

  .zg-discussion-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 0px;
    .zg-current-discussion-title {
      box-shadow: $box-shadow;
      border: 1px solid $zg-purple;
      border-radius: 3px;
      padding: 7px;
      color: $zg-purple;
      font-weight: bolder;
      margin: 20px;
    }
    .zg-link-and-create-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      .zg-copy-link {
        margin-left: 8px;
        font-size: 11px;
        .zg-icon-button {
        }
      }
    }
  }

  .zg-position-container {
    display: flex;
    flex-direction: column;
    color: $zg-purple;
    font-size: 20px;
    font-weight: bold;

    .zg-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;
      h4 {
        padding-bottom: 3px;
        margin: 10px 0;
        border-bottom: 1px solid $zg-purple;
      }
    }

    .zg-list-agree {
      margin-top: 15px;
      border: solid 1px rgb(64, 255, 0);
      margin-right: 20px;
      border-radius: 0% 3% 3% 0;
    }

    .zg-list-neutral {
      border: solid 1px rgb(242, 255, 0);
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 3%;
    }

    .zg-list-disagree {
      border: solid 1px rgb(255, 0, 0);
      margin-left: 20px;
      border-radius: 3% 0 0 3%;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-current-discussion {
    // min-height: 640px;
    .zg-discussion-header {
      padding-left: 15px;
      display: flex;
      flex-direction: column;
      // background: rgb(241, 243, 255);
      min-height: 73px;

      margin-bottom: 12px;
      .zg-current-discussion-title {
        margin: 25px 0 5px 0;
      }
      .zg-link-and-create-holder {
        display: flex;
        flex-direction: row;
        margin: 10px 5px 5px 5px;
        .zg-copy-link {
          // margin-left: 15px;
          // font-size: 11px;
          // margin-top: 3px;
          .zg-icon-button {
          }
        }
      }
    }
    .zg-position-container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-evenly;
      color: $zg-purple;
      font-size: 20px;
      font-weight: bold;

      .zg-list {
        margin: 0;
        width: 33vw;
        min-height: 550px;
      }
      .zg-list-agree {
        margin-top: 0;
        margin-right: 0px;
        border-radius: 0% 3% 3% 0;
      }

      .zg-list-neutral {
        margin-right: 10px;
        margin-left: 10px;
        border-radius: 3%;
      }

      .zg-list-disagree {
        margin-left: 0px;
        border-radius: 3% 0 0 3%;
      }
    }
  }
}
