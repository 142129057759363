@import "../../style/style.scss";

.zg-dashboard {
  min-height: 540px;
}

@media only screen and (min-width: 501px) {
  .zg-dashboard {
    // min-height: 75vw;
    display: flex;
    flex-direction: row;
    // align-items: stretch;
    // align-content: stretch;
  }
}
