@import "../../style/style.scss";

.zg-create-discussion {
  .zg-open-discussion-modal {
    // background: #d3b349;
    font-family: $zg-font;
    box-shadow: $box-shadow;
    width: 200px;
    color: $zg-purple;
    text-decoration: none;
    font-weight: bolder;
    font-size: 12px;

    .zg-add-icon {
      margin: 5px 4px;
      height: 17px;
    }
  }
}

.zg-create-action {
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  border: none;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .zg-create-discussion-button-text {

  }
}

p {
  font-family: $zg-font;
}

.zg-create-discussion-modal {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .zg-create-discussion-body {
    border-radius: 5px;
    text-align: center;
    height: 400px;
    width: 330px;
    position: absolute;
    background: white;
    color: $zg-purple;
    .zg-create-discussion-button {
      font-family: $zg-font;
      color: $zg-purple;
      font-weight: bolder;
    }
    .zg-cancel-discussion-button {
      color: $zg-purple;
      font-family: $zg-font;
      font-weight: bolder;
    }

    .zg-create-discussion-valid {
      background: #d3b349;
      box-shadow: $box-shadow;
    }

    .zg-discussion-input {
      margin-top: 20px;
      width: 250px;
      height: 100px;
      font-size: 18px;
      font-weight: bold;
      border: none;
      outline: none;
      color: $zg-purple;
    }
  }
}

.zg-create-discussion-body {
}

@media only screen and (min-width: 501px) {
  
  .zg-create-discussion-modal {
    .zg-create-discussion-body {
      height: 500px;
      width: 700px;
      .zg-create-discussion-button {
      }
      .zg-cancel-discussion-button {
      }
      .zg-discussion-input {
        width: 400px;
        height: 270px;
      }
    }
  }
}
