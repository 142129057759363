@import "../../style/style.scss";

.zg-category {
  .zg-category-header {
    display: flex;
    justify-content: center;
    .zg-category-header-text {
      font-size: 20px;
      font-weight: bold;
      color: $zg-purple;
      border-bottom: 1px solid $zg-purple;
      padding: 7px 5px;
      margin: 15px;
    }
  }

  .zg-category-button-holder {
    .zg-category-action-holder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .zg-back-to-topics {
        width: 170px;
        box-shadow: $box-shadow;
        margin: 20px 0 0 0;
        text-decoration: none;
        font-weight: bolder;
      }
    }
    .zg-discussion-list {
      padding: 20px;
      margin: 0 40px 0 40px;
      .zg-category-link-holder {
        margin: 5px 5px 15px 5px;
        color: $zg-purple;
        border: 1px solid $zg-purple;
        border-radius: 3px;
        display: flex;
        text-decoration: none;
        .zg-discussion-link {
          font-weight: bolder;
          padding: 15px;
          font-family: $zg-font;
        }
      }
    }
    .zg-need-discussions {
      margin: 15px;
      color: $zg-purple;
      font-weight: bold;
    }
  }
}

@media only screen and (min-width: 501px) {
  .zg-category {
    box-shadow: $box-shadow;
    margin-top: 0;
    border-radius: 5px 5px 5px 5px;
    min-width: 85vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .zg-category-button-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .zg-category-action-holder {
      }
      .zg-discussion-list {
        min-height: 500px;

        display: flex;
        flex-direction: column;
        .zg-category-link-holder {
          text-align: left;
          margin: 5px 0;
          .zg-discussion-link {
            font-size: 14px;
            
          }
        }
      }
    }
  }
}
